<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card style="border-radius: 15px !important">
      <v-card-title class="grid-close">
        <span class="headline">Generar QR</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          :loading="loading"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row class="pa-5">
            <v-col cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  <h3>Color de fondo</h3>

                  <input
                    class="control-input"
                    type="color"
                    style="height: 40px; border-radius: 15px !important"
                    @change="generateQr"
                    v-model="backgroundColor"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h3>Color de los puntos</h3>
                  <input
                    class="control-input"
                    type="color"
                    style="height: 40px; border-radius: 15px"
                    @change="generateQr"
                    v-model="dotColor"
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <h3>Estilo de los puntos</h3>
                  <v-select
                    @change="generateQr"
                    :items="types"
                    rounded
                    hide-details
                    style="border-radius: 15px"
                    :loading="loading"
                    outlined
                    v-model="dotType"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h3>Tamaño del logo</h3>
                  <v-select
                    @change="generateQr"
                    :items="size"
                    :loading="loading"
                    rounded
                    style="border-radius: 15px"
                    hide-details
                    outlined
                    v-model="imageSize"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h3>Márgenes del logo</h3>
                  <v-select
                    @change="generateQr"
                    :items="margin"
                    rounded
                    hide-details
                    style="border-radius: 15px"
                    :loading="loading"
                    outlined
                    v-model="imageMargin"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h3>Color del borde de las esquinas</h3>

                  <input
                    class="control-input"
                    type="color"
                    style="height: 40px border-radius:15px;"
                    @change="generateQr"
                    v-model="cornerBorderColor"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h3>Color del punto de las esquinas</h3>

                  <input
                    class="control-input"
                    type="color"
                    style="height: 40px border-radius:15px;"
                    @change="generateQr"
                    v-model="cornerColor"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <!-- <p><b>Vista previa</b></p> -->
              <div id="canvas"></div>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-5">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  class="save-btn px-12"
                  color="primary"
                  style="border-radius: 15px"
                  @click="download"
                  dark
                  height="50px"
                  >Descargar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import QRCodeStyling from "qr-code-styling";
import { mapState } from "vuex";

export default {
  name: "generate-qr",
  props: ["url"],
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: "",
      width: 250,
      height: 250,
      type: "scv",
      backgroundColor: "#ffffff",
      dotColor: "",
      dotType: "rounded",
      types: [
        { text: "Cuadrado", value: "square" },
        { text: "Redondeado", value: "rounded" },
        { text: "Extra Redondeado", value: "extra-rounded" },
        { text: "Puntos", value: "dots" },
        { text: "Classy", value: "classy" },
        { text: "Classy Redondeado", value: "classy-rounded" },
      ],
      imageSize: 0.5,
      imageMargin: 8,
      size: [0.2, 0.3, 0.4, 0.5],
      margin: [1, 2, 4, 6, 8, 10],
      cornerColor: "#000000",
      cornerBorderColor: "#000000",
      qrCode: null,
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },

  watch: {
    imageSize() {
      this.generateQr();
    },
    imagePadding() {
      this.generateQr();
    },
  },
  methods: {
    generateQr() {
      this.loading = true;
      this.qrCode = new QRCodeStyling({
        width: this.width,
        height: this.height,
        type: this.type,
        data: this.url,
        image: this.selectedBusiness.logo.original,
        dotsOptions: {
          color: this.dotColor,
          type: this.dotType,
        },
        backgroundOptions: {
          color: this.backgroundColor,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: this.imageMargin,
          imageSize: this.imageSize,
        },
        cornersSquareOptions: {
          color: this.cornerBorderColor,
        },
        cornersDotOptions: {
          color: this.cornerColor,
        },
        qrOptions: {
          typeNumber: 5,
        },
      });
      document.getElementById("canvas").innerHTML = "";
      this.qrCode.append(document.getElementById("canvas"));
      this.loading = false;
    },

    download() {
      this.loading = true;
      this.qrCode.download({
        name: `imagen-qr-${this.selectedBusiness.name}`,
        extension: "png",
      });
      this.loading = false;
    },
  },

  mounted() {
    this.dotColor = this.selectedBusiness.qrThemeColor;
    this.cornerBorderColor = this.selectedBusiness.qrThemeColor;

    if (window.innerWidth > 560) {
      this.width = 400;
      this.height = 400;
    } else {
      this.width = 250;
      this.height = 250;
    }

    this.generateQr();
  },
};
</script>

<style scoped>
#canvas {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
</style>