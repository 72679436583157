<template>
  <v-app class="app-vue" id="inspire">
    <v-app-bar
      color="primary-color"
      dark
      v-if="appFrame && isPhone"
      max-height="60px"
      style="height: 60px !important"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <Logo :type="mini" style="width: 80px" />
      <v-spacer></v-spacer>

      <v-text-field
        v-if="$store.state.visibleSearch && showSearch"
        v-model="search"
        solo-inverted
        height="20px"
        dark
        filled
        clearable
        style="border-radius: 15px !important"
        flat
        dense
        hide-details
        :label="`Buscar ${$store.state.searchTerm}`"
        prepend-inner-icon="fa-search"
        class="d-md-flex color-search"
      />
      <v-icon v-if="!showSearch" @click="showSearch = true" class="mr-3">
        fas fa-search
      </v-icon>
    </v-app-bar>
    <v-app-bar
      app
      color="white"
      key="app_bar"
      clipped-right
      elevation="0"
      class="appbar pt-5"
      v-if="appFrame && !isPhone"
    >
      <div class="user ml-6">
        <v-list-item
          two-line
          class="pa-0"
          style="border-radius: 15px !important"
        >
          <v-list-item-avatar class="my-1 ml-2">
            <img src="~@/assets/user.svg" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 font-weight-bold">
              {{ user.name }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{
                ["oplAdmin", "oplSales", "oplFinance", "oplLesserAdmin", "oplOperations",].includes(user.type)
                  ? "Administrador"
                  : selectedBusiness.name
              }}
            </v-list-item-subtitle>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  absolute
                  right
                  class="elevation-0"
                  fab
                  v-on="on"
                  @click="settings"
                >
                  <v-icon color="primary"> fas fa-edit </v-icon>
                </v-btn>
              </template>
              <span>Configuraciones de usuario</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-text-field
        v-if="$store.state.visibleSearch"
        v-model="search"
        solo-inverted
        light
        filled
        style="border-radius: 15px !important"
        flat
        height="50"
        dense
        hide-details
        :label="`Buscar ${$store.state.searchTerm}`"
        prepend-inner-icon="fa-search"
        class="d-none d-md-flex color-search"
      />
      <v-spacer v-if="user.type == 'oplBusiness'" />
      <v-btn
        v-if="user.type == 'oplBusiness'"
        style="border-radius: 10px !important"
        color="primary"
        elevation="0"
        icon
        outlined
        width="38"
        height="38"
        large
        class="ml-2"
        @click="qrDialog = true"
      >
        <v-icon dark large> fas fa-qrcode </v-icon>
      </v-btn>
      <v-spacer />

      <span
        class="headline font-weight-bold ml-0 mr-5 d-none d-md-block"
        style="position: relative; color: #ff5900; top: -6px"
      >
        Plataforma
        <span
          style="
            position: absolute;
            bottom: -18px;
            left: 0;
            font-size: 14px;
            font-weight: normal;
            color: rgba(0, 0, 0, 0.4);
            width: 100%;
            text-align: right;
          "
        >
          Administrativa
        </span>
      </span>
    </v-app-bar>
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />

    <v-navigation-drawer
      v-model="drawer"
      app
      color="primary-color"
      :mini-variant.sync="mini"
      permanent
      class="navigations primary-color b-r"
      key="app_nav"
      dark
      v-if="navBar && !isPhone"
    >
      <div class="logo-l">
        <Logo :type="mini" :style="`width: ${mini ? '30px' : '100px '}`" />

        <v-btn v-if="!mini" absolute right icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>

      <v-list nav dense style="border-radius: 15px !important">
        <v-divider class="mb-3"></v-divider>

        <v-list-item-group
          v-model="selectedItem"
          dark
          style="border-radius: 15px !important"
        >
          <v-list-item
            v-for="(item, i) in menuItems"
            v-if="item.allowedRoles.includes(user.type)"
            :key="i"
            dark
            class="border-r pl-3"
            style="border-radius: 15px !important"
            :to="`${item.link}`"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <transition name="slide-fade">
        <v-list
          nav
          color="#ffffff2b"
          expand
          dense
          v-if="selectedBusiness_ && ['oplAdmin', 'oplOperations', 'oplLesserAdmin'].includes(user.type)"
        >
          <v-list-group
            :value="true"
            active-class="selected-business"
            style="border-radius: 15px !important"
            color="#fff"
          >
            <template v-if="mini" v-slot:activator>
              <v-list-item-avatar
                id="businessMenu"
                style="transform: translateX(-7px)"
              >
                <v-img
                  :src="selectedBusiness_l.logo.original || defaulImage"
                  max-height="90%"
                  max-width="90%"
                >
                </v-img>
              </v-list-item-avatar>
            </template>

            <template v-else v-slot:activator>
              <v-list-item-avatar id="businessMenu" left>
                <v-img
                  :src="selectedBusiness_l.logo.original || defaulImage"
                  max-height="80%"
                  max-width="80%"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectedBusiness_l.name }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(e, index) in selectedBusiness_lMenu"
              :key="index"
              style="border-radius: 15px !important"
              :to="`${e.link}`"
            >
              <v-list-item-icon class="ml-1">
                <v-icon v-text="e.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="e.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </transition>

      <div class="actions-closed">
        <v-btn
          @click="logOut"
          color="ligth"
          style="padding: 10px !important; margin: 5px; border-radius: 15px"
          block
          large
          :elevation="0"
        >
          <v-icon small>fa-sign-out-alt</v-icon>
          <span v-if="!mini" class="ml-2 white--text font-weight-500">
            Cerrar Sesión
          </span>
        </v-btn>
      </div>
    </v-navigation-drawer>
    <div class="disclamer" v-if="navBar && !isPhone">
      <span>
        <i class="fas fa-code-branch"></i>
        v 0.1.0 de
        <i class="fas fa-mug-hot"></i> a <i class="fas fa-code"></i> por:
        <b>
          <a class="white--text" href="http://ocho.life" target="_blank"
            >OCHO APP.
          </a>
        </b>
        &copy; Ocho Corporation S.A. de C.V.
      </span>
    </div>

    <v-navigation-drawer
      app
      v-model="drawer"
      absolute
      temporary
      color="primary-color"
      dark
      v-if="navBar && isPhone"
    >
      <div class="logo-l">
        <Logo :type="mini" :style="`width: ${mini ? '30px' : '100px '}`" />
      </div>

      <v-list-item-group
        v-model="selectedItem"
        dark
        style="border-radius: 15px !important"
      >
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          dark
          class="border-r pl-3"
          style="border-radius: 15px !important"
          :to="`${item.link}`"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              style="color: white"
              v-text="item.text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <div class="actions-closed">
        <v-btn
          @click="logOut"
          color="ligth"
          style="padding: 10px !important; margin: 5px; border-radius: 15px"
          block
          large
          :elevation="0"
        >
          <v-icon small>fa-sign-out-alt</v-icon>
          <span v-if="!mini" class="ml-2 white--text font-weight-500">
            Cerrar Sesión
          </span>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-dialog
      persistent
      v-if="qrDialog"
      v-model="qrDialog"
      max-width="900px"
      style="border-radius: 15px !important"
    >
      <qr
        @cancel="qrDialog = false"
        :url="url"
        @success="qrDialog = false"
      ></qr>
    </v-dialog>
    <v-main class="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";
import qr from "@/components/generateQR";
import { mapActions, mapState } from "vuex";
import TransitionPage from "./components/TransitionPage";
import Snackbar from "@/components/snackbar";
import Logo from "@/components/logo";
import { menuItems } from "./menu";

export default {
  name: "App",
  components: {
    qr,
    Snackbar,
    Logo,
    TransitionPage,
  },
  data: () => ({
    qrDialog: false,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],

    user: {
      name: null,
      type: null,
    },
    baseRoute: {
      oplAdmin: "/transactions",
      oplBusiness: "/transactions",
      oplOperations: "/transactions",
      oplLesserAdmin: "/transactions",
      oplSales: "/transactions",
      oplFinance: "/transactions",
    },
    mini: false,
    drawer: true,
    appFrame: null,
    navBar: null,
    cards: [],
    loading: false,
    selectedItem: 0,
    selectedBusiness_l: {},
    showSearch: false,
    defaulImage: require("@/assets/templateImage.svg"),
    sudo: [
      {
        text: "Transacciones",
        icon: "fa-regular fa-sack-dollar",
        link: "/transactions",
      },
      {
        text: "Comercios",
        icon: "fa-solid fa-store",
        link: "/bussines",
      },
      {
        text: "Usuarios",
        icon: "fa-solid fa-user",
        link: "/users",
      },
      {
        text: "Métodos de pago",
        icon: "fa-solid fa-credit-card",
        link: "/payment-gateways",
      },
      {
        text: "Reportes de pago",
        icon: "fas fa-list",
        link: "/payment-reports",
      },
    ],
    selectedBusiness_lMenu: [
      {
        text: "Configuración",
        icon: "fa fa-cogs",
        link: "/configuration",
      },
      {
        text: "Cuenta de banco",
        icon: "fa-solid fa-piggy-bank",
        link: "/bank-accounts",
      },
      {
        text: "Enlaces de Pago",
        icon: "fa fa-link",
        link: "/links",
      },
    ],
    bussines: [
      {
        text: "Transacciones",
        icon: "fa-regular fa-sack-dollar",
        link: "/transactions",
      },
      {
        text: "Enlaces de Pago",
        icon: "fa fa-link",
        link: "/links",
      },
      {
        text: "Mis clientes",
        icon: "fa-regular fa-users",
        link: "/business/users",
      },

      {
        text: "Suscripciones",
        icon: "fa-regular fa-star",
        link: "/business/subscriptions",
      },
      {
        text: "Cobros masivos",
        icon: "fa-regular fa-credit-card",
        link: "/business/massive",
      },

      {
        text: "Configuración",
        icon: "fa fa-cogs",
        link: "/configuration",
      },
      {
        text: "Reporte de transacciones",
        icon: "fas fa-list",
        link: "/report-sales",
      },
    ],
  }),

  methods: {
    ...mapActions([
      "titlepage",
      "setUser",
      "Alert_",
      "setUserValidity_",
      "addBusiness",
      "setCards",
    ]),

    settings() {
      if (this.$router.currentRoute.name != "profile") {
        this.$router.push({ path: "/profile" });
      }
    },
    logOut() {
      if (this.user) {
        fb.auth().signOut();
      }
    },

    uploadImg(typeimg) {
      const image = this.File;
      fb.app()
        .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-opl-businesses`)
        .ref()
        .child(`${this.idBussines}/${typeimg}.${image.type.split("/")[1]}`)
        .put(image)
        .then(() => {
          this.Alert_({
            text: "La imagen fue Actualizada",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "success",
          });
          this.updateimageSuccess();
        })
        .catch(() => {
          this.Alert_({
            text: "Ocurrió un error al subir la imágen",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.menuItems = menuItems
    this.appFrame = this.$route.meta.appFrame;
    this.navBar = this.$route.meta.navBar;
    this.titlepage(this.$route.meta.titlepage);

    fb.auth().onAuthStateChanged(async (authUser) => {
      let router = this.$router.currentRoute;

      if (this.$route.meta.basePath) {
        if (!authUser) {
          this.loading = false;

          if (this.$router.history.current.fullPath != "/") {
            this.$router.push({ path: "/" });
          }
          return;
        }

        db.collection("appUsers")
          .doc(authUser.uid)
          .onSnapshot(async (reponse) => {
            let user = reponse.data();
            user[".key"] = authUser.uid;

            this.user = user;

            if (!user.type || !user.active) {
              this.setUserValidity_(false);

              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();

              return;
            }

            this.setUser(user);

            if (user.type == "oplBusiness") {
              let business = await db
                .collection("OPLBusinesses")
                .doc(user.businessId)
                .get();

              business = business.data();
              business[".key"] = user.businessId;

              this.addBusiness(business);
            }

            if (router.name === "login") {
              this.$router.push({
                path: this.baseRoute[this.user.type],
              });

              return;
            }

            if (
              router.meta.allowedRoles &&
              !router.meta.allowedRoles.includes(user.type)
            ) {
              this.$router
                .push({ path: this.baseRoute[this.user.type] })
                .catch((err) => console.log(err));
            }
          });
      }
    });
  },
  computed: {
    ...mapState(["role", "selectedBusiness", "search"]),
    url() {
      // console.log(this.selectedBusiness);
      return `${process.env.VUE_APP_URL}/process/transaction/${this.selectedBusiness.qrCode}`;
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
    isPhone() {
      const is = screen.width < 500 ? true : false;
      return is;
    },
    typeMenu_list() {
      console.log(this.role);
      if (this.role == "oplAdmin") {
        return this.sudo;
      }
      if (this.role == "oplBusiness") {
        let menu = this.bussines;

        if (this.user.otpScreen) {
          menu.push({
            text: "OTP de verificación",
            icon: "fas fa-sms",
            link: "/business/otp",
          });
        }

        return menu;
      }
    },
    selectedBusiness_() {
      this.selectedBusiness_l = this.selectedBusiness;
      return this.selectedBusiness_l;
    },
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      this.titlepage(e.meta.titlepage);
    },
    cards(e) {
      this.setCards(e);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "@/_responsive.scss";
@import "@/main.scss";
.app-vue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  overflow-x: clip;
  @include responsive(mobile) {
    padding-top: 0px;
  }
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
.icon-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.v-snack__content {
  text-align: center !important;
}
.border-r {
  border-radius: 15px !important;
  overflow: hidden !important;
}
.actions-closed {
  height: 60px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}
.navigations {
  height: calc(100vh - 55px) !important;
  margin: 15px;
  -webkit-filter: drop-shadow(0 0 10px rgba(255, 89, 0, 0.429));
  filter: drop-shadow(0 0 10px rgba(255, 89, 0, 0.415));
}
.logo-l {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transform: translateX(-15px);
}
.user {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 15px;
  box-sizing: border-box;
  min-width: 300px;
  height: 60px;
  margin-right: 20px;
  @include responsive(mobile) {
    height: auto;
    min-width: auto;
    padding-right: 20px;
    //... all what you want
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.control-input {
  width: 100%;
}

input[type="color"] {
  height: 50px !important;
  border-radius: 15px !important;
}
.appbar {
  top: 0;
  position: fixed !important;
  padding-bottom: 60px !important;
  z-index: 1000;
  @include responsive(mobile) {
    position: relative !important;
    height: auto;
    background-color: aqua;
    display: flex;
    flex-direction: column;
  }
}
.disclamer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  font-size: 12px;
  z-index: 10;
  background-color: #272727;
  color: #fff !important;
  padding: 3px 10px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.selected-business {
  background-color: transparent;
}

.selected-business:hover,
.selected-business:visited {
  // background: #ff651f !important;
  border-radius: 10px !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list-item-group .v-list-item--active {
  color: white;
}

@media only screen and (max-width: 599px) {
  .v-dialog {
    margin: 5px;
  }

  .dashboard {
    margin-top: 10px;
    padding-left: 10px;
  }
}
</style>
