import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "ocho-pay",
  storage: window.localStorage,
  // modules: ["titlepage", "selectedBusiness"],
});

export default new Vuex.Store({
  state: {
    search: "",
    searchTerm: "",
    visibleSearch: true,
    user: {},
    role: "",
    business: {},
    selectedBusiness: null,
    userIsValid: true,
    titlepage: "3232",
    appFrame: false,
    navBar: false,
    cards: [],
    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
  },
  mutations: {
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },
    setUser(state, target) {
      state.user = target;
      state.role = target.type;
    },
    setSelectedBusiness(state, target) {
      state.selectedBusiness = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    visibleSearch(state, target) {
      state.visibleSearch = target;
    },
    setSearch(state, target) {
      state.search = target;
    },

    setCards(state, target) {
      state.cards = target;
    },
    setSearchTerm(state, target) {
      state.searchTerm = target;
    },
    settitlepage(state, target) {
      state.titlepage = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },
    _searchTerm(state, target) {
      state.searchTerm = target;
    },
    setUserValidity(state, target) {
      state.userIsValid = target;
      setTimeout(() => {
        state.userIsValid = true;
      }, 3000);
    },
  },
  actions: {
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },
    titlepage: ({ commit, state }, t) => {
      commit("settitlepage", t);
    },
    visibleSearch: ({ commit, state }, t) => {
      commit("visibleSearch", t);
    },
    addBusiness: ({ commit, state }, business) => {
      commit("setSelectedBusiness", business);
    },
    setUser: ({ commit, state }, user) => {
      commit("setUser", user);
    },

    setCards: ({ commit, state }, cards) => {
      commit("setCards", cards);
    },

    _searchTerm: ({ commit, state }, dat) => {
      commit("_searchTerm", dat);
    },
    setUserValidity_: ({ commit, state }, i) => {
      commit("setUserValidity", i);
    },

    setUserValidity_: ({ commit, state }, i) => {
      commit("setUserValidity", i);
    },
  },
  getters: {
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
    title: (state) => {
      return state.titlepage;
    },

    userIsValid: (state) => state.userIsValid,
    userType: (state) => state.user.type,
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
