import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/login.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      appFrame: true,
      titlepage: "Transacciones",
      navBar: true,
      basePath: true,
      allowedRoles: ["oplAdmin", "oplBusiness", "oplSales", "oplFinance", "oplLesserAdmin", "oplOperations",],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/transactions.vue"
      ),
  },
  {
    path: "/special-users",
    name: "specialUser",
    meta: {
      appFrame: true,
      titlepage: "Usuarios especiales",
      navBar: true,
      basePath: true,
      allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/specialUsers.vue"),
  },
  {
    path: "/users",
    name: "user",
    meta: {
      appFrame: true,
      titlepage: "Usuarios",
      navBar: true,
      basePath: true,
      allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/users.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: {
      appFrame: true,
      titlepage: "Configuración",
      navBar: true,
      basePath: true,
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplAdmin", "oplBusiness"],
    },
    component: () => import("../views/dashboard/settings/profile.vue"),
  },

  {
    path: "/payment-gateways",
    name: "paymentGateways",
    meta: {
      appFrame: true,
      titlepage: "Métodos de pago",
      navBar: true,
      basePath: true,
      allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/paymentGateways.vue"
      ),
  },
  {
    path: "/process/transaction/:id",
    name: "ProcessTransaction",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: false,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment-qr.vue"),
  },
  {
    path: "/payment-reports",
    name: "paymentReport",
    meta: {
      appFrame: true,
      titlepage: "Reportes de pago",
      navBar: true,
      basePath: true,
      allowedRoles: ["oplAdmin", "oplSales", "oplFinance", "oplOperations", "oplLesserAdmin",],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/paymentReport.vue"
      ),
  },
  {
    path: "/configuration",
    name: "configuration",
    meta: {
      appFrame: true,
      titlepage: "Configuración",
      navBar: true,
      basePath: true,
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplAdmin", "oplBusiness"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/configuration.vue"
      ),
  },
  {
    path: "/report-sales",
    name: "ReportSales",
    meta: {
      appFrame: true,
      titlepage: "Reporte de transacciones",
      navBar: true,
      basePath: true,
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplAdmin", "oplBusiness"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/paymentSalesBusiness.vue"
      ),
  },
  {
    path: "/bank-accounts",
    name: "bankAccounts",
    meta: {
      appFrame: true,
      titlepage: "Cuentas Bancarias",
      navBar: true,
      basePath: true,
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplAdmin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/bank-accounts/bank-accounts.vue"
      ),
  },
  {
    path: "/links",
    name: "links",
    meta: {
      appFrame: true,
      titlepage: "Enlaces de Pago",
      navBar: true,
      basePath: true,
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplAdmin", "oplBusiness"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/links.vue"),
  },

  // {
  //   path: "/not-found",
  //   name: "not found",
  //   meta: {
  //     appFrame: false,
  //     navBar: false,
  //     basePath: false,
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  // },

  {
    path: "/bussines",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      titlepage: "Comercios",
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplBusiness", "oplAdmin"],
    },
    name: "bussines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/bussines.vue"),
  },
  {
    path: "/business/users",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      titlepage: "Usuarios",
      allowedRoles: ["oplBusiness"],
    },
    name: "business-users",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/business/users.vue"
      ),
  },
  {
    path: "/business/massive",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      titlepage: "Cobros masivos",
      allowedRoles: ["oplBusiness"],
    },
    name: "business-massive",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/business/massive/massive.vue"
      ),
  },
  {
    path: "/business/subscriptions",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      titlepage: "Suscripciones",
      allowedRoles: ["oplBusiness"],
    },
    name: "business-massive",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/business/subscriptions/list.vue"
      ),
  },
  {
    path: "/business/otp",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      titlepage: "OTP de verificación",
      //TODO: Ask if new roles can access this
      allowedRoles: ["oplBusiness"],
    },
    name: "business-massive",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/business/otp/list.vue"
      ),
  },
];

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
});

export default router;
