import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";
import vueCurrencyDirective from "./plugins/vue-currency-directive/src/main";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import { VueMaskFilter } from "v-mask";

Vue.filter("VMask", VueMaskFilter);

library.add(faUserSecret);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.directive("currency", vueCurrencyDirective);

Vue.config.productionTip = false;
Vue.use(VueFirestore);
Vue.use(firebase);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
