export const menuItems = [
    {
        text: "Transacciones",
        icon: "fa-regular fa-sack-dollar",
        link: "/transactions",
        allowedRoles: ["oplAdmin", "oplBusiness", "oplSales", "oplFinance", "oplLesserAdmin", "oplOperations",],
    },
    {
        text: "Usuarios especiales",
        icon: "fas fa-user-cog",
        link: "/special-users",
        allowedRoles: ["oplAdmin",],
    },
    {
        text: "Comercios",
        icon: "fa-solid fa-store",
        link: "/bussines",
        allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    {
        text: "Usuarios",
        icon: "fa-solid fa-user",
        link: "/users",
        allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    {
        text: "Métodos de pago",
        icon: "fa-solid fa-credit-card",
        link: "/payment-gateways",
        allowedRoles: ["oplAdmin", "oplLesserAdmin", "oplOperations",],
    },
    {
        text: "Reportes de pago",
        icon: "fas fa-list",
        link: "/payment-reports",
        allowedRoles: ["oplAdmin", "oplSales", "oplFinance", "oplOperations", "oplLesserAdmin",],
    },
    {
        text: "Enlaces de Pago",
        icon: "fa fa-link",
        link: "/links",
        allowedRoles: ["oplBusiness"],
    },
    {
        text: "Mis clientes",
        icon: "fa-regular fa-users",
        link: "/business/users",
        allowedRoles: ["oplBusiness"],
    },
    {
        text: "Suscripciones",
        icon: "fa-regular fa-star",
        link: "/business/subscriptions",
        allowedRoles: ["oplBusiness"],
    },
    {
        text: "Cobros masivos",
        icon: "fa-regular fa-credit-card",
        link: "/business/massive",
        allowedRoles: ["oplBusiness"],
    },
    {
        text: "Configuración",
        icon: "fa fa-cogs",
        link: "/configuration",
        allowedRoles: ["oplBusiness"],
    },
    {
        text: "Reporte de transacciones",
        icon: "fas fa-list",
        link: "/report-sales",
        allowedRoles: ["oplBusiness"],
    }      
]